//libs
import { CSG } from 'three-csg-ts';

//models
import { HouseType } from "./model/houseType";
import { RoofType } from "@/properties/model/roofType";

import { ModelDimensions } from "./model/modelDimensions";
import { HouseBaseModel } from "./model/houseBaseModel";
import { CardinalDirection } from "@/properties/model/cardinalDirection";
import { FloorType } from "@/properties/model/floorType";
import {  Group, Mesh, MeshStandardMaterial, Object3D, Shape, ExtrudeGeometry} from 'three';
import { BuildingPart } from '@/properties/model/buildingPart';

export const defaultDimensions: ModelDimensions = {
    walls: {
        [CardinalDirection.NORTH]: {
            width: 8,
            height: 3,
            depth: 0.4
        },
        [CardinalDirection.SOUTH]: {
            width: 8,
            height: 3,
            depth: 0.4
        },
        [CardinalDirection.WEST]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
        [CardinalDirection.EAST]: {
            width: 10,
            height: 3,
            depth: 0.4
        }
    },
    floors: {
        [FloorType.ERDGESCHOSS]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
        [FloorType.MITTELGESCHOSS]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
        [FloorType.DACHGESCHOSS]: {
            width: 10,
            height: 3,
            depth: 0.4
        },
    },
    roof: {
        [CardinalDirection.WEST]: {
            width: 10,
            height: 3,
            depth: 0.4,
            degree: 30
        },
        [CardinalDirection.EAST]: {
            width: 10,
            height: 3,
            depth: 0.4,
            degree: 30
        }
    }
}

export class Bungalow extends HouseBaseModel {
    brickMats: MeshStandardMaterial | undefined = undefined;
    roofMats: MeshStandardMaterial | undefined = undefined;

    constructor(customDimensions = defaultDimensions, customRoofType?: RoofType, customMats?: any) {
        super(customDimensions, HouseType.BUNGALOW, customRoofType ?? RoofType.WALMDACH);

        if (customMats) {
            if (customMats["wall"]) {
                this.brickMats = customMats["wall"];
            }

            if (customMats["roof"]) {
                this.roofMats = customMats["roof"];
            }
        }
    }

    private createWall(width: number, height: number, depth: number, color: number, amountWindows = 0, amountDoors = 0): Mesh {
        let newWall = this.createBoxGeometry(width, height, depth, color);
        newWall.position.set(width / 2, height / 2, 0);
        newWall.updateMatrix();


        const wallSegmets = amountWindows + amountDoors;
        const segmentWidth = width / wallSegmets;
        const segmentInfo: any = [];

        for (let iSeg = 0; iSeg < wallSegmets; iSeg++) {
            const info = {
                width: segmentWidth,
                segmentCenterX: iSeg * (segmentWidth / 2) + (segmentWidth / 2),
                segmentCenterY: 0.5 * height,
                segmentCeterZ: 0
            };

            segmentInfo.push(info);
        }


        for (let i = 0; i < amountWindows; i++) {
            const wallCSG = CSG.fromMesh(newWall);

            const winBox = this.createBoxGeometry(0.8, 1.4, 0.4, 0xFFFFFF);
            winBox.position.set(i * (segmentWidth) + (segmentWidth / 2), 0.5 * height, 0);
            winBox.updateMatrix();


            newWall = CSG.toMesh(wallCSG.subtract(CSG.fromMesh(winBox)), newWall.matrix, newWall.material);
        }

        if (this.brickMats) {
            newWall.material = this.brickMats;
        }

        return newWall;
    }

    public validateDimensions(): boolean {
        return true;
    }

    public createModel(): Object3D {
        const houseGroup = new Group();

        return houseGroup;
    }

    public createDefaultModel(): void {
        const houseGroup = new Group();

        {   // NORTH
            const firstFloor = this.createWall(8, 3, 0.4, 0xa33514, 2);
            firstFloor.position.set(4, 1.5, 0);
            firstFloor.userData = { buildingPart: BuildingPart.WALL, cardinalDirection: CardinalDirection.NORTH };
            houseGroup.add(firstFloor);
        }

        {   // EAST
            const firstFloor = this.createWall(8.2, 3, 0.4, 0xa33514, 2);
            firstFloor.position.set(7.8, 1.5, 4.3);
            this.rotateObject(firstFloor, 0, 90);
            firstFloor.userData = { buildingPart: BuildingPart.WALL, cardinalDirection: CardinalDirection.EAST };
            houseGroup.add(firstFloor);
        }

        {   // SOUTH
            const firstFloor = this.createWall(8, 3, 0.4, 0xa33514, 2);
            firstFloor.position.set(4, 1.5, 8.6);
            firstFloor.userData = { buildingPart: BuildingPart.WALL, cardinalDirection: CardinalDirection.SOUTH };
            houseGroup.add(firstFloor);
        }


        {   // WEST
            const firstFloor = this.createWall(8.2, 3, 0.4, 0xa33514, 2);
            firstFloor.position.set(0.2, 1.5, 4.3);
            this.rotateObject(firstFloor, 0, 90);
            firstFloor.userData = { buildingPart: BuildingPart.WALL, cardinalDirection: CardinalDirection.WEST };
            houseGroup.add(firstFloor);
        }

        {   // BOTTOM PLATE <-> GROUND FLOOR
            const floorObj = this.createBoxGeometry(7.2, 0.4, 8.2, 0xcfcfcf);
            floorObj.position.set(4, 0.2, 4.3);
            floorObj.userData = { buildingPart: BuildingPart.FLOOR };
            houseGroup.add(floorObj);

        }

        if (this.roofType === RoofType.WALMDACH) {

            {   // FIRST FLOOR <-> SECOND FLOOR
                const floorObj = this.createBoxGeometry(7.2, 0.4, 8.2, 0xcfcfcf);
                floorObj.position.set(4, 2.8, 4.3);
    
                houseGroup.add(floorObj);
            }
    
            const triangleShape = new Shape()
            .moveTo(-1, 3)
            .lineTo(9, 3)
            .lineTo(4, 6.65)
            .lineTo(-1, 3);

            {
                const extrudeSettings = { depth: 0.2, bevelEnabled: false };
                const geometry = new ExtrudeGeometry(triangleShape, extrudeSettings);

                let material = new MeshStandardMaterial({ color: 0xb4b4b4 });
                if (this.roofMats) {
                    material = this.roofMats;
                }

                const triangleNorth: any = new Mesh(geometry, material);
                triangleNorth.translateZ(-2.5);
                triangleNorth.translateY(0.15);
                triangleNorth.translateX(0.1);
                this.rotateObject(triangleNorth, 37.5);

                triangleNorth.updateMatrix();

                triangleNorth.userData = { buildingPart: BuildingPart.ROOF, cardinalDirection: CardinalDirection.NORTH, roofType: RoofType.WALMDACH };


               houseGroup.add(triangleNorth);
            }

            {
                const extrudeSettings = { depth: 0.2, bevelEnabled: false };
                const geometry = new ExtrudeGeometry(triangleShape, extrudeSettings);

                let material = new MeshStandardMaterial({ color: 0xb4b4b4 });
                if (this.roofMats) {
                    material = this.roofMats;
                }
                const triangleNorth: any = new Mesh(geometry, material);
                triangleNorth.translateZ(11.1);
                triangleNorth.translateX(0.1);
                this.rotateObject(triangleNorth, -37.5);

                triangleNorth.updateMatrix();

                triangleNorth.userData = { buildingPart: BuildingPart.ROOF, cardinalDirection: CardinalDirection.SOUTH, roofType: RoofType.WALMDACH };


               houseGroup.add(triangleNorth);
            }

            // Maße für das Trapez
            const bottomWidth = 10; // Breite der unteren Basis
            const topWidth = 5.4;     // Breite der oberen Basis
            const height = 5.6;       // Höhe des Trapezes
            const depth = 0.2;      // Tiefe des Trapezes

            // Definiere die Form des Trapezes
            const trapezoidShape = new Shape();
            trapezoidShape.moveTo(-bottomWidth / 2, 0);          // Unten links
            trapezoidShape.lineTo(bottomWidth / 2, 0);           // Unten rechts
            trapezoidShape.lineTo(topWidth / 2, height);         // Oben rechts
            trapezoidShape.lineTo(-topWidth / 2, height);        // Oben links
            trapezoidShape.lineTo(-bottomWidth / 2, 0);          // Zurück zu unten links

            // Extrude-Optionen
            const extrudeSettings = {
                depth: depth,         // Die Tiefe des Trapezes
                bevelEnabled: false,  // Keine abgeschrägten Kanten
            };

            { // West

                // Geometrie durch Extrusion erstellen
                const trapezoidGeometry = new ExtrudeGeometry(trapezoidShape, extrudeSettings);

                // Material für das Trapez
                let material = new MeshStandardMaterial({ color: 0xb4b4b4 });
                if (this.roofMats) {
                    material = this.roofMats;
                }

                // Mesh erstellen und zur Szene hinzufügen
                const trapezoid = new Mesh(trapezoidGeometry, material);
                trapezoid.translateZ(4.4);
                trapezoid.translateY(2.6);
                trapezoid.translateX(-0.8);

                this.rotateObject(trapezoid, 0, 90);
                this.rotateObject(trapezoid, 60);

                trapezoid.userData = { buildingPart: BuildingPart.ROOF, cardinalDirection: CardinalDirection.WEST, roofType: RoofType.WALMDACH };

                houseGroup.add(trapezoid);
            }

            { // East

                // Geometrie durch Extrusion erstellen
                const trapezoidGeometry = new ExtrudeGeometry(trapezoidShape, extrudeSettings);

                // Material für das Trapez
                let material = new MeshStandardMaterial({ color: 0xb4b4b4 });
                if (this.roofMats) {
                    material = this.roofMats;
                }


                // Mesh erstellen und zur Szene hinzufügen
                const trapezoid = new Mesh(trapezoidGeometry, material);
                trapezoid.translateZ(4.4);
                trapezoid.translateY(2.4);
                trapezoid.translateX(8.9);

                this.rotateObject(trapezoid, 0, 90);
                this.rotateObject(trapezoid, -60);

                trapezoid.userData = { buildingPart: BuildingPart.ROOF, cardinalDirection: CardinalDirection.EAST, roofType: RoofType.WALMDACH };

                houseGroup.add(trapezoid);
            }

        } else if (this.roofType === RoofType.FLACHDACH) {
            const flatRoof = this.createBoxGeometry(7.2, 0.4, 8.2, 0xb4b4b4);
            flatRoof.position.set(4, 2.8, 4.3);
            flatRoof.userData = { buildingPart: BuildingPart.ROOF, roofType: RoofType.FLACHDACH };
            houseGroup.add(flatRoof);
        } else if (this.roofType === RoofType.SATTELDACH) {
            // invalid rooftype
            console.warn("Bungalows do not support gable roofs!");
        }

        this.house3Model = houseGroup;
    }
}