//libs

//models
import { HouseType } from "./houseType";
import { RoofType } from "@/properties/model/roofType";
import { ModelDimensions } from "./modelDimensions";
import { BoxGeometry, Material, MathUtils, Mesh, MeshStandardMaterial, Object3D } from "three";

// TODO: Custom Validator für ModelDimensions, der durch erbende Klassen implementiert wird
export class HouseBaseModel {
    private _houseType: HouseType;
    private _roofType: RoofType;

    private _modelDimensions: ModelDimensions;

    private highlightInterval: number | undefined = undefined;

    protected _house3Model;

    constructor(modelDimensions: ModelDimensions, houseType: HouseType, roofType: RoofType) {
        this._modelDimensions = modelDimensions;
        this._houseType = houseType;
        this._roofType = roofType;
    }

    protected rotateObject(object: Object3D, degreeX = 0, degreeY = 0, degreeZ = 0) {
        if (degreeX !== 0) {
            object.rotateX(MathUtils.degToRad(degreeX));
        }

        if (degreeY !== 0) {
            object.rotateY(MathUtils.degToRad(degreeY));
        }

        if (degreeZ !== 0) {
            object.rotateZ(MathUtils.degToRad(degreeZ));
        }
    }

    protected createBoxGeometry(width: number, height: number, depth: number, color = 0x00000): Mesh {
        const geometry = new BoxGeometry(width, height, depth);
        const material = new MeshStandardMaterial({ color: color });
        const box = new Mesh(geometry, material);

        const pos = box.geometry.getAttribute('position'),
            nor = box.geometry.getAttribute('normal'),
            uvs = box.geometry.getAttribute('uv');

        for (let i = 0; i < pos.count; i++) {
            let x = 0,
                y = 0;

            const nx = Math.abs(nor.getX(i)),
                ny = Math.abs(nor.getY(i)),
                nz = Math.abs(nor.getZ(i));

            // if facing X
            if (nx >= ny && nx >= nz) {
                x = pos.getZ(i);
                y = pos.getY(i);
            }

            // if facing Y
            if (ny >= nx && ny >= nz) {
                x = pos.getX(i);
                y = pos.getZ(i);
            }

            // if facing Z
            if (nz >= nx && nz >= ny) {
                x = pos.getX(i);
                y = pos.getY(i);
            }

            uvs.setXY(i, x, y);
        }

        return box;
    }

    public clearHighlight() {
        if (this.highlightInterval !== undefined) {
            clearInterval(this.highlightInterval);
        }
        this._house3Model.traverse(obj => {
            let mat: Material;
            if (Array.isArray(obj.material)) {
                mat = obj.material[0];
            } else {
                mat = <MeshStandardMaterial>obj.material;
            }
            if (mat) {
                if (!mat.userData || !mat.userData["isCloned"]) {
                    mat = mat.clone();
                    mat.userData = { isCloned: true };

                }

                mat.opacity = 1;
                if (Array.isArray(obj.material)) {
                    obj.material = [mat];
                } else {
                    obj.material = mat;
                }
            }
        })
    }

    // nur eine Gruppe von objects auf einmal
    public highlightObject(objects: Mesh[]) {
        this.clearHighlight();

        this._house3Model.traverse(obj => {
            if (obj instanceof Mesh) {
                let mat: Material;
                if (Array.isArray(obj.material)) {
                    mat = obj.material[0];
                } else {
                    mat = <MeshStandardMaterial>obj.material;
                }

                if (mat) {
                    if (!mat.userData || !mat.userData["isCloned"]) {
                        mat = mat.clone();
                        mat.userData = { isCloned: true };

                    }

                    mat.opacity = 0.25;
                    mat.transparent = true;
                    if (Array.isArray(obj.material)) {
                        obj.material = [mat];
                    } else {
                        obj.material = mat;
                    }
                }
            }
        })

        this.highlightInterval = setInterval(() => {
            for (let i = 0; i < objects.length; i++) {
                let mat: Material;
                if (Array.isArray(objects[i].material)) {
                    mat = objects[i].material[0];
                } else {
                    mat = <MeshStandardMaterial>objects[i].material;
                }

                if (!mat.userData || !mat.userData["isCloned"]) {
                    mat = mat.clone();
                    mat.transparent = true;
                    mat.userData = { isCloned: true };

                    if (Array.isArray(objects[i].material)) {
                        objects[i].material = [mat];
                    } else {
                        objects[i].material = mat;
                    }
                }

                if (!objects[i].userData) {
                    objects[i].userData = {};
                }
                objects[i].userData["isHighlighted"] = true;

                mat.opacity = 1.25 + 1 * Math.sin(new Date().getTime() * .0025);
            }
        }, 50);
    }

    /** getter & setter */
    public get houseType() {
        return this._houseType;
    }

    public get roofType() {
        return this._roofType;
    }

    public set roofType(newType: RoofType) {
        this._roofType = newType;
    }

    public get modelDimensions() {
        return this._modelDimensions;
    }

    public set modelDimensions(newDimensions: ModelDimensions) {
        this._modelDimensions = newDimensions;
    }

    protected set house3Model(model: Object3D) {
        this._house3Model = model;
    }

    public get house3Model() {
        return this._house3Model;
    }

    /** class logic */
    public validateDimensions(): boolean {
        return true;
    }


    /** three model creation */
    public createModel(): void {
        return;
    }
}